<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>How do I apply for an aviation medical?</h2>
      <p>
        An aviation medical is issued by CASA and typically requires a physical
        examination by a medical doctor. There are
        <a href="https://www.casa.gov.au/licences-and-certification/aviation-medicine/classes-medical-certificates">
        various classes of aviation medical
        </a>
        and it is <b>very important</b> that you discuss which class is suitable for you
        with your instructor before your application. Each circumstance is different.
      </p>
      <p>
        Before applying for an aviation medical, you will first need an
        <router-link to="/how-to-apply-for-arn">ARN</router-link> from CASA.
      </p>
      <p>
        For a Class 1 or Class 2 aviation medical, you will need to make an appointment
        to see a Designated Aviation Medical Examiner (DAME). You can find a DAME located
        near you by searching on
        <a href="https://www.casa.gov.au/licences-and-certification/aviation-medicine/search-medical-examiner-or-eye-examiner/designated">
        the CASA website
        </a>.
      </p>
      <p>
        If your instructor has recommended that you to apply for a Basic Class 2
        aviation medical, you can apply for this through any medical doctor who performs
        medical examinations for commercial vehicle drivers. You can also apply for a
        Basic Class 2 aviation medical through any DAME. Note that a <b>Basic Class 2</b>
        and a <b>Class 2</b> aviation medical are different standards with different
        examination requirements.
      </p>
      <p>
        It is recommended to start your aviation medical application process early in your
        flight training, since it can be delayed by unforeseen circumstances. An aviation
        medical certificate (Basic Class 2 or higher) is required before you can conduct
        solo flight training at Archerfield during tower hours.
      </p>
      <p>
        Once you have obtained your aviation medical, it comes with some important, but
        simple, legal obligations. You will find the regulations using the search for
        <quote>"Civil Aviation Regulations 1988"</quote>
        and
        <quote>"Civil Aviation Safety Regulations 1998"</quote> on
        <a href="https://legislation.gov.au">legislation.gov.au</a>.
      </p>
    </div>
    <MedicalCertificateObligations />
  </div>
</template>

<script>
import StudentFAQ from '@/components/StudentFAQ.vue'
import MedicalCertificateObligations from '@/components/MedicalCertificateObligations.vue'

export default {
  components: {
    StudentFAQ,
    MedicalCertificateObligations
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
</style>
